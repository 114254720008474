// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-aim-box-js": () => import("./../../../src/pages/aim-box.js" /* webpackChunkName: "component---src-pages-aim-box-js" */),
  "component---src-pages-ask-an-analyst-js": () => import("./../../../src/pages/ask-an-analyst.js" /* webpackChunkName: "component---src-pages-ask-an-analyst-js" */),
  "component---src-pages-ask-for-discount-js": () => import("./../../../src/pages/ask-for-discount.js" /* webpackChunkName: "component---src-pages-ask-for-discount-js" */),
  "component---src-pages-b-2-b-b-2-c-consulting-js": () => import("./../../../src/pages/b2b-b2c-consulting.js" /* webpackChunkName: "component---src-pages-b-2-b-b-2-c-consulting-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-comtex-pressrelease-js": () => import("./../../../src/pages/comtex-pressrelease.js" /* webpackChunkName: "component---src-pages-comtex-pressrelease-js" */),
  "component---src-pages-consulting-solutions-js": () => import("./../../../src/pages/consulting-solutions.js" /* webpackChunkName: "component---src-pages-consulting-solutions-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-data-analytics-js": () => import("./../../../src/pages/data-analytics.js" /* webpackChunkName: "component---src-pages-data-analytics-js" */),
  "component---src-pages-data-market-monitoring-js": () => import("./../../../src/pages/data-market-monitoring.js" /* webpackChunkName: "component---src-pages-data-market-monitoring-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-js": () => import("./../../../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-pages-it-solutions-js": () => import("./../../../src/pages/it-solutions.js" /* webpackChunkName: "component---src-pages-it-solutions-js" */),
  "component---src-pages-market-report-research-latest-published-reports-js": () => import("./../../../src/pages/market-report-research/latest-published-reports.js" /* webpackChunkName: "component---src-pages-market-report-research-latest-published-reports-js" */),
  "component---src-pages-market-research-reports-js": () => import("./../../../src/pages/market-research-reports.js" /* webpackChunkName: "component---src-pages-market-research-reports-js" */),
  "component---src-pages-news-and-resources-js": () => import("./../../../src/pages/news-and-resources.js" /* webpackChunkName: "component---src-pages-news-and-resources-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-press-release-js": () => import("./../../../src/pages/press-release.js" /* webpackChunkName: "component---src-pages-press-release-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-published-research-connected-car-security-market-js": () => import("./../../../src/pages/published-research/connected-car-security-market.js" /* webpackChunkName: "component---src-pages-published-research-connected-car-security-market-js" */),
  "component---src-pages-published-research-sheer-quadrant-js": () => import("./../../../src/pages/published-research/sheer-quadrant.js" /* webpackChunkName: "component---src-pages-published-research-sheer-quadrant-js" */),
  "component---src-pages-regions-js": () => import("./../../../src/pages/regions.js" /* webpackChunkName: "component---src-pages-regions-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-request-brochure-js": () => import("./../../../src/pages/request-brochure.js" /* webpackChunkName: "component---src-pages-request-brochure-js" */),
  "component---src-pages-request-sample-js": () => import("./../../../src/pages/request-sample.js" /* webpackChunkName: "component---src-pages-request-sample-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/searchResults.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-seo-and-translation-services-js": () => import("./../../../src/pages/seo-and-translation-services.js" /* webpackChunkName: "component---src-pages-seo-and-translation-services-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-submit-query-js": () => import("./../../../src/pages/submit-query.js" /* webpackChunkName: "component---src-pages-submit-query-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-ask-an-analyst-template-js": () => import("./../../../src/templates/askAnAnalystTemplate.js" /* webpackChunkName: "component---src-templates-ask-an-analyst-template-js" */),
  "component---src-templates-ask-for-discount-template-js": () => import("./../../../src/templates/askForDiscountTemplate.js" /* webpackChunkName: "component---src-templates-ask-for-discount-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-category-report-template-js": () => import("./../../../src/templates/categoryReportTemplate.js" /* webpackChunkName: "component---src-templates-category-report-template-js" */),
  "component---src-templates-category-sector-report-template-js": () => import("./../../../src/templates/categorySectorReportTemplate.js" /* webpackChunkName: "component---src-templates-category-sector-report-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-press-template-js": () => import("./../../../src/templates/pressTemplate.js" /* webpackChunkName: "component---src-templates-press-template-js" */),
  "component---src-templates-region-report-template-js": () => import("./../../../src/templates/regionReportTemplate.js" /* webpackChunkName: "component---src-templates-region-report-template-js" */),
  "component---src-templates-region-sector-report-template-js": () => import("./../../../src/templates/regionSectorReportTemplate.js" /* webpackChunkName: "component---src-templates-region-sector-report-template-js" */),
  "component---src-templates-report-template-js": () => import("./../../../src/templates/reportTemplate.js" /* webpackChunkName: "component---src-templates-report-template-js" */),
  "component---src-templates-request-brochure-template-js": () => import("./../../../src/templates/requestBrochureTemplate.js" /* webpackChunkName: "component---src-templates-request-brochure-template-js" */),
  "component---src-templates-request-sample-template-js": () => import("./../../../src/templates/requestSampleTemplate.js" /* webpackChunkName: "component---src-templates-request-sample-template-js" */)
}

